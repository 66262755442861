import { useEffect, useRef, useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import { useDisclosure } from '@mantine/hooks'

import { useParams } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'
import { MetaTags } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import Button from 'src/components/Buttons/Button/Button'
import { DatePickerFilter } from 'src/components/Inputs/DatePickerFilter/DatePickerFilter'
import { SearchBox } from 'src/components/Inputs/SearchBox/SearchBox'
import PaginationArea from 'src/components/Navigation/PaginationArea/PaginationArea'
import ActionsMenu from 'src/components/Overlays/ActionsMenu/ActionsMenu'
import CheckboxMenu from 'src/components/Overlays/CheckboxMenu/CheckboxMenu'
import EmailSenderModal from 'src/components/Overlays/EmailSenderModal/EmailSenderModal'
import { toast } from 'src/components/Overlays/Toast/Toast'
import { VoEStatus } from 'src/graphql/types/verificationOfEmployments'
import { GET_VOE_LIST, SEND_INSTRUCTIONS_EMAIL } from 'src/graphql/voe.graphql'
import { usePage } from 'src/hooks/usePage/usePage'
import IconChevronDown from 'src/icons/IconChevronDown'
import IconFilter from 'src/icons/IconFilter'

import VoERequestsTable from './components/VoERequestsTable/VoERequestsTable'
import { loadFiltersFromUrl } from './loadFiltersFromUrl'
import { updateFiltersInUrl } from './updateFiltersInUrl'

const onError = () => {
  toast('Something went wrong, please try again.', 'error')
}

const LIMIT = 10

const VoERequestsPage = () => {
  const buttonRef = useRef(null)

  const filters = loadFiltersFromUrl()

  const statuses = [
    { label: 'Pending review', value: VoEStatus.PENDING_REVIEW },
    { label: 'Completed', value: VoEStatus.COMPLETED },
  ]

  const [selectedStatuses, setSelectedStatuses] = useState(
    statuses.filter((status) => filters.statuses.includes(status.value))
  )

  const [searchText, setSearchText] = useState(filters.searchText)

  const [page, setPage] = usePage()

  const { currentUser } = useAuth()

  const [opened, { open, close }] = useDisclosure(false)

  const [loadVoEList, { data: voeList, loading }] = useLazyQuery(GET_VOE_LIST, {
    onError,
  })

  const [sendIntructionsEmail, { loading: loadingInstructionsEmail }] =
    useMutation(SEND_INSTRUCTIONS_EMAIL, {
      onCompleted: () => {
        close()
        toast('Email sent', 'success')
      },
      onError: () => {
        close()
        toast('Unable to save application. Please try again later.', 'error')
      },
    })

  const urlParams = useParams()
  useEffect(() => {
    const filters = loadFiltersFromUrl()

    loadVoEList({
      variables: {
        filters,
        pageInfo: { offset: (page - 1) * LIMIT, limit: LIMIT },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, urlParams])

  useEffect(() => {
    updateFiltersInUrl({
      ...filters,
      statuses: selectedStatuses.map((status) => status.value),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStatuses])

  const onDateRangeChange = (dateRange: { from: Date; to: Date }) => {
    updateFiltersInUrl({
      ...filters,
      createdAt: {
        ...filters.createdAt,
        gte: dateRange.from,
        lte: dateRange.to,
      },
    })
  }

  const onSearch = (text: string) => {
    updateFiltersInUrl({ ...filters, searchText: text })
  }

  const onSendEmail = ({ email }) => {
    sendIntructionsEmail({
      variables: {
        emailTo: email,
      },
    })
  }

  const firstName = currentUser?.firstName
  const lastName = currentUser?.lastName
  const fullName = `${firstName} ${lastName}`
  const emailAddress = currentUser?.email
  const phoneNumber = currentUser?.phone
  const companyName = currentUser?.companyName

  const emailBody = `Hello,

I am ${fullName} from ${companyName}, reaching out with instructions to submit any employment verification requests you may have for drivers who have previously been part of our team.

To request verification for an individual's employment with us, please follow the steps below:
  - Access our secure Employment Verification Request portal via this website: https://dashboard.getdoublenickel.com/external-voe-request
  - Fill in the required fields with the applicant's information. Ensure all information is accurate; if our records do not match your request we will not be able to verify prior employment.
  - Submit the request, and our team will process the request and provide a timely response.

We aim to provide prompt and accurate information to support your verification process. Should there be any additional information or specific concerns you wish to address, feel free to contact us directly at ${emailAddress} or ${phoneNumber}.

We appreciate your cooperation and look forward to assisting you.

Best regards,
${companyName}`

  return (
    <div className="flex h-full w-full flex-col gap-5">
      <MetaTags
        title="Incoming VoE Requests"
        description="Incoming VoE Requests"
      />
      <div className="flex h-full flex-col gap-5 px-5 pb-3">
        <div className="flex flex-col gap-2 ">
          <div className="flex flex-1  flex-row ">
            <div className="flex flex-col">
              <div className="text-lg font-semibold text-doubleNickel-gray-900">
                Incoming VoE Requests
              </div>
              <div className="text-sm text-doubleNickel-gray-600">
                {/* These spans are necessary to prevent Google Translate issues  */}
                <span>{voeList?.verificationOfEmployments.totalCount}</span>
                <span> incoming requests</span>
              </div>
            </div>
            <div className="flex flex-1 flex-row items-center justify-end gap-4">
              <div className="w-[50%]">
                <SearchBox
                  value={searchText}
                  placeholder="Press Enter to search by driver name or company name"
                  onSearch={onSearch}
                  setValue={setSearchText}
                ></SearchBox>
              </div>

              <CheckboxMenu
                items={statuses}
                selectedItems={selectedStatuses}
                setSelectedItems={setSelectedStatuses}
                fallbackText={'No status found'}
              >
                <Button
                  ref={buttonRef}
                  variant="outline"
                  text={`Status: ${
                    selectedStatuses.length === 0
                      ? 'All'
                      : selectedStatuses.length
                  }`}
                  lefticon={<IconFilter />}
                />
              </CheckboxMenu>
              <DatePickerFilter
                interval={{
                  gte: filters.createdAt?.gte,
                  lte: filters.createdAt?.lte,
                }}
                onChange={(dateRange) => onDateRangeChange(dateRange)}
              />
              <ActionsMenu
                text="Actions"
                rightIcon={<IconChevronDown />}
                items={[
                  {
                    label: 'Send instructions',
                    onClick: () => open(),
                  },
                ]}
              />
            </div>
          </div>
          <VoERequestsTable
            loading={loading}
            voeList={voeList?.verificationOfEmployments.items}
          />
        </div>
        <PaginationArea
          page={page}
          totalPages={Math.ceil(
            voeList?.verificationOfEmployments?.totalCount / LIMIT
          )}
          onPageChange={setPage}
        />
      </div>
      <EmailSenderModal
        from={currentUser?.email}
        title="Send instructions via Email"
        subject={`Instructions for Requesting Employment Verification from ${fullName}`}
        body={emailBody}
        isOpen={opened}
        onClose={close}
        loading={loadingInstructionsEmail}
        onSend={onSendEmail}
      />
    </div>
  )
}

export default VoERequestsPage
