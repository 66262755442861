import { VoEType } from 'src/graphql/types/verificationOfEmployments'

const stringToDate = (dateAsString) => {
  const [month, day, year] = dateAsString.split('/')
  return new Date(year, month - 1, day)
}

export function loadFiltersFromUrl() {
  const searchParams = new URLSearchParams(location.search)

  const filters = {
    type: VoEType.INBOUND,
    statuses: [],
    searchText: '',
    createdAt: {
      gte: null,
      lte: null,
    },
  }

  const filterKeys = ['statuses', 'searchText', 'createdAt']

  filterKeys.forEach((filterKey) => {
    const filterValue = searchParams.get(filterKey)
    if (filterValue) {
      if (filterKey === 'statuses') {
        filters.statuses = filterValue.split(',')
      }

      if (filterKey === 'searchText') {
        filters.searchText = searchParams.get('searchText')
      }

      if (filterKey === 'createdAt') {
        const values = JSON.parse(filterValue)
        if (values.gte) {
          filters.createdAt.gte = stringToDate(values.gte)
        }
        if (values.lte) {
          filters.createdAt.lte = stringToDate(values.lte)
        }
      }
    }
  })
  return filters
}
