import dayjs from 'dayjs'

import { navigate } from '@redwoodjs/router'
const DATE_FORMAT = 'MM/DD/YYYY'

export function updateFiltersInUrl(filters) {
  const searchParams = new URLSearchParams(location.search)

  if (filters?.searchText) {
    searchParams.set('searchText', filters.searchText)
  } else {
    searchParams.delete('searchText')
  }

  for (const filterKey in filters) {
    const filter = filters[filterKey]

    if (filterKey === 'statuses') {
      if (filter?.length > 0) {
        searchParams.set(filterKey, filter)
      } else {
        searchParams.delete(filterKey)
      }
    }

    if (filterKey === 'createdAt') {
      const values = {
        gte: filter.gte ? dayjs(filter.gte).format(DATE_FORMAT) : null,
        lte: filter.lte ? dayjs(filter.lte).format(DATE_FORMAT) : null,
      }
      if (values.gte || values.lte) {
        searchParams.set(filterKey, JSON.stringify(values))
      } else {
        searchParams.delete(filterKey)
      }
    }
  }
  navigate(`?${searchParams.toString()}`, { replace: true })
}
